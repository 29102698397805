import Breadcrumbs from "../modules/Breadcrumbs";
import {Col} from "react-bootstrap";

type Props = {
  title: string | undefined,
  description: string | undefined,
  breadcrumbs?: IBreadcrumbs[],
  media?: IAction[]
};

const PageLayout = ({title, description, breadcrumbs, media}: Props) => {
  let viewPort: number = media && media.length > 0 ? 6 : 12
  return (
    <Col sm={viewPort} lg={viewPort} xxl={viewPort}>
      {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs}/>}
      <h3 className={`title ${(breadcrumbs && breadcrumbs.length > 1) ? "mt-4" : ""}`}>{title ? title : ""}</h3>
      <p className="description">{description ? description : ""}</p>
    </Col>
  )
}

export default PageLayout;