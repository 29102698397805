import { useMemo, useState } from "react";
import { Params, useParams } from "react-router-dom";
import { MockTopic } from "../mocks/MockTopic";
import {MockContent} from "../mocks/MockContent";
import MainLayout from "../components/layouts/MainLayout";
import PageLayout from "../components/layouts/PageLayout";
import { useGetContentQuery, useGetTopicQuery } from "../store/backoffice.slice";

const Content = () => {
  const { topic_ref, content_ref }: Readonly<Params> = useParams();
  
  const [topic, setTopic] = useState<ITopic>(MockTopic);
  const [content, setContent] = useState<IContent>(MockContent);

  const { data: _topic, isLoading: isLoadingTopic } = useGetTopicQuery(topic_ref);
  const { data: _content, isLoading } = useGetContentQuery(`${topic_ref}/${content_ref}`);

  useMemo(() => {
    const data = (isLoadingTopic || !_topic) ? MockTopic : _topic.data;
    setTopic(data);
  }, [_topic, isLoadingTopic]);

  useMemo(() => {
    const data = (isLoading || !_content) ? MockContent : _content.data;
    setContent(data);
  }, [_content, isLoading])

  return (
    <MainLayout loadState={isLoading} title={topic.name} menu={topic.sidebarTree}>
      <section className="inner-content">
        <main className="no-scrollbar">
          <PageLayout title={content?.title} description={content?.intro} breadcrumbs={content?.breadcrumbs}/>
          <div dangerouslySetInnerHTML={{__html: content.content}} className="mt-5"/>
        </main>
        {/*<AsideSection/>*/}
      </section>
    </MainLayout>
  );
}

export default Content;