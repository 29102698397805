import { Container, Image } from "react-bootstrap";
import HeroSVG from "../../assets/images/hero.svg";

const HeroSection = () => (
  <section className="hero-section">
    <Container fluid="lg">
      <div className="d-flex gap-4 justify-content-between align-items-center">
        <div>
          <h4>truQ Documentations</h4>
          <p className="text-medium mt-1">Assistance tool for all truQ products and services.</p>
        </div>
        <div className="my-3" >
          <Image src={HeroSVG} alt="HERO" />
        </div>
      </div>
    </Container>
  </section>
);
export default HeroSection;