import {Fragment, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Collapse, Image} from "react-bootstrap";
import {Link, useParams} from "react-router-dom";
import {useSideNav} from "../modules/useToggleSideMenu";
import ArrowUpSVG from "../../assets/images/arrow_up.svg";
import ArrowDownSVG from "../../assets/images/arrow_down.svg";

type Props = {
  title: string,
  menu: ISideMenu[],
};

const SideMenu = ({title, menu}: Props) => {
  const menuMode = useSideNav(), navigate = useNavigate();
  const {topic_ref, content_ref} = useParams();
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState<string>("");
  const viewContent = (link: string): void => navigate(`/${link}`)

  const handleToggleDropDown = (topic: ISideMenu): void => {
    if (!topic.label) {
      viewContent(topic.href);
      setOpen(false);
    } else {
      setActive(topic.slug);
      setOpen(true);
    }
  }

  return (
    <aside className={`side-menu sidebar-sticky ${menuMode.sideMenuState ? "d-block" : "d-none d-lg-block"}`}>
      <div className="sidebar-sticky">
        {title &&
          <div className="mb-4 ms-3">
            <Link to={'/'}><small>&#8592; home</small></Link>
            <h6 onClick={() => viewContent(topic_ref ?? '')} className="mt-4 cursor-pointer">{title}</h6>
          </div>
        }

        <ul className="nav">
          {menu && menu.map((item: ISideMenu, index: number) =>
            <Fragment key={index}>
              {(item.label && item.tree)
                ? <li className={`nav-item ${(item.slug === active) ? "show" : "hide"}`}
                      aria-controls={item.slug}
                      onClick={() => handleToggleDropDown(item)}>
                  <span className="nav-link">
                    <span>{item.title}</span>
                    {(item.slug === active) ? <Image src={ArrowUpSVG} alt="UP" width={12} height={12}/>
                      : <Image src={ArrowDownSVG} alt="DOWN" width={12} height={12}/>
                    }
                  </span>
                  <Collapse in={open}>
                    <ul id={active} className="nav-submenu">
                      {item.tree.map((element: ISideSubMenu, elementIndex: number) => (
                        <li key={elementIndex} className={`nav-item ${(element.slug === content_ref) ? "active" : ""}`}>
                          <div onClick={() => viewContent(element.href)} className={`nav-link`}>{element.title}</div>
                        </li>
                      ))}
                    </ul>
                  </Collapse>
                </li>
                : <li className={`nav-item ${(item.slug === content_ref) ? "active" : ""}`}>
                  <div onClick={() => handleToggleDropDown(item)} className={`nav-link`}>
                    <span>{item.title}</span>
                  </div>
                </li>
              }
            </Fragment>
          )}
        </ul>
      </div>
    </aside>
  );
}

export default SideMenu;