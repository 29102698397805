import { NavLink } from "react-router-dom";
import { Col, Container, Image, Row } from "react-bootstrap";
import Logo from "../assets/images/truq-logo.png";
import EmptySVG from "../assets/images/empty_field.svg";

const Error404 = () => (
  <Container className="min-vh-100">
    <Image src={Logo} alt="LOGO" className="my-5 mx-3" width={90} height={40} />
    <Row className="position-absolute top-50 start-50 translate-middle w-100">
      <Col lg={5} xxl={6}><Image src={EmptySVG} alt="LOGO" className="d-block mx-auto"/></Col>
      <Col lg={7} xxl={6}>
        <div className="text-center mt-4 mt-lg-5 px-2">
          <h2 className="mb-2">404 - Page Not Found</h2>
          <small className="px-3">
            We have no clue what you’re looking for. Let’s help you navigate to a familiar page.
          </small>
          <div className="mt-5"><NavLink className="btn btn-primary text-decoration-none" to="/">Go to Home Page</NavLink></div>
        </div>
      </Col>
    </Row>
    <div className="position-absolute bottom-0 start-50 translate-middle w-75 text-center pb-1">
      <small>&copy; truQ 2024</small>
      <small className="d-block">All-in-one Third Party Logistics (3PL) solution</small>
    </div>
  </Container>
);

export default Error404;