export const TopicNavs: ITopicNav[] = [
  {
    title: 'Get Started',
    actions: [
      {
        title: 'Get started',
        type: "topic",
        href: 'get-started-with-truq-documentation'
      }
    ]
  },
  {
    title: "Merchants",
    actions: [
      {
        title: "Merchants and Partnerships",
        type: "link",
        href: "https://truq-services.gitbook.io/merchants"
      }
    ]
  },
  {
    title: "Trip Bookings",
    actions: [
      {
        title: "Booking a Trip",
        type: "link",
        href: "https://truq-services.gitbook.io/merchants/booking-management/trip-booking"
      },
      {
        title: "Trip status and tracking update",
        href: "trip-status-and-tracking-update",
        type: "topic"
      }
    ]
  },
  {
    title: "Siju by truQ",
    actions: [
      {
        title: "Getting Started",
        type: "topic",
        href: "getting-started-with-siju-by-truq"
      },
      {
        title: "Onboarding",
        type: "topic",
        href: "onboarding-on-siju"
      },
      {
        title: "Trip Pool",
        type: "topic",
        href: "open-trips"
      },
      {
        title: "Booking Management",
        type: "topic",
        href: ""
      },
      {
        title: "Inventory Management",
        type: "topic",
        href: "inventory-management"
      },
      {
        title: "Reconciliation",
        type: "topic",
        href: "trip-reconciliation"
      }
    ]
  }
];

export const MoreInfoProps: IMoreInfo[] = [
  {
    title: "Getting Started",
    subtitle: "Set Up Git",
    description: `Lorem ipsum dolor sit amet consectetur. Nec vel et gravida consectetur amet rhoncus. Sodales at eget purus id maecenas scelerisque. Convallis convallis euismod non ullamcorper. Nibh
            dui pulvinar at hendrerit suspendisse ultrices consequat tortor.`
  },
  {
    title: "Fequently Asked Questions",
    subtitle: "FAQ's",
    description: `Lorem ipsum dolor sit amet consectetur. Nec vel et gravida consectetur amet rhoncus. Sodales at eget purus id maecenas scelerisque. Convallis convallis euismod non ullamcorper. Nibh
            dui pulvinar at hendrerit suspendisse ultrices consequat tortor.`
  },
  {
    title: "Popular Services",
    subtitle: "Set Up Git",
    description: `Lorem ipsum dolor sit amet consectetur. Nec vel et gravida consectetur amet rhoncus. Sodales at eget purus id maecenas scelerisque. Convallis convallis euismod non ullamcorper. Nibh
            dui pulvinar at hendrerit suspendisse ultrices consequat tortor.`
  },
  {
    title: "Meet Me",
    subtitle: "Cynthia",
    description: `Lorem ipsum dolor sit amet consectetur. Nec vel et gravida consectetur amet rhoncus. Sodales at eget purus id maecenas scelerisque. Convallis convallis euismod non ullamcorper. Nibh
            dui pulvinar at hendrerit suspendisse ultrices consequat tortor.`
  },
]