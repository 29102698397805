import { ReactElement, createContext, useContext, useState } from "react";

type ContextType = {
  sideMenuState: boolean,
  toggleSideNav: (value?: boolean) => void,
}

const SideMenuContext = createContext<ContextType>({
  sideMenuState: false,
  toggleSideNav: (value?: boolean) => {},
});


export const useSideNav = () => useContext(SideMenuContext);


export const SideMenuContextProvider = ({ children }: { children: ReactElement}) => {
  const [sideMenuState, setSideMenuState] = useState(false);

  const toggleSideNav = (value?: boolean) => {
    if (value) setSideMenuState(value);
    else setSideMenuState(prev => !prev);
  };
  
  const value = { sideMenuState, toggleSideNav };

  return (
    <SideMenuContext.Provider value={value}>
      {children}
    </SideMenuContext.Provider>
  );
};

