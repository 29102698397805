import { MockBredCrumb } from "./MockComponent";
import { MockTopic } from "./MockTopic";

export const MockContent: IContent = {
  id: "",
  title: "",
  intro: "",
  slug: "",
  seo: "",
  content: "",
  featured: false,
  is_active: true,
  topic: MockTopic,
  label: {},
  breadcrumbs: [MockBredCrumb],
}