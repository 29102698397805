import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Toaster} from "react-hot-toast";

import Home from "./pages/Home";
import Topic from "./pages/Topic";
import Error404 from "./pages/Error404";
import Content from "./pages/Content";
import UseScrollToTop from "./components/modules/UseScrollToTop";
import {MenuType} from "./utils/enums";

const App = () => (
  <Router>
    <Toaster containerStyle={{zIndex: '99999999'}} position="bottom-left"/>
    <UseScrollToTop/>
    <Routes>
      {['/', MenuType.HOME].map((path: string) => <Route path={path} key={path} element={<Home/>}/>)}
      <Route path=":topic_ref" element={<Topic/>}/>
      <Route path=":topic_ref/:content_ref" element={<Content/>}/>
      <Route path="*" element={<Error404/>}/>
    </Routes>
  </Router>
);
export default App;