import { MockActions, MockBredCrumb } from "./MockComponent";

export const MockTopic: ITopic = {
  topic: "",
  name: "",
  actions: [MockActions],
  media_action: [],
  sidebarTree: [],
  id: "",
  intro: "",
  breadcrumbs: [MockBredCrumb],
}