import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const UseScrollToTop = () => {
  const { pathname } = useLocation();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window && window.scrollY > 300) setIsVisible(true);
      else setIsVisible(false);
    };

    window.addEventListener('scroll', toggleVisibility);
    window.scrollTo(0, 0);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [pathname]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div>
      {isVisible && <button className="scroll-to-top" onClick={scrollToTop}>↑</button>}
    </div>
  )
}

export default UseScrollToTop;