import { Container, Row } from "react-bootstrap";
import Footer from "../components/layouts/Footer";
import Header from "../components/layouts/Header";
import HeroSection from "../components/modules/HeroSection";
import Topics from "../components/layouts/Topics";

const Home = () => {
  return (
    <main>
      <Header/>
      <HeroSection/>
      <Container fluid="lg" className="my-5">
        <Row className="my-5"><Topics/></Row>
        {/*<Row className="my-5"><MoreInfo/></Row>*/}
        <Footer/>
      </Container>
    </main>
  );
}

export default Home;