import {ReactElement} from "react";
import Header from "./Header";
import Footer from "./Footer";
import SideMenu from "./SideMenu";
import Loader from "../modules/Loader";

type Props = {
  title: string,
  menu: ISideMenu[],
  loadState: boolean;
  children: ReactElement | ReactElement[];
};

const MainLayout = ({children, title, menu, loadState}: Props) => {
  return (
    <main>
      <Header/>
      {menu && <SideMenu title={title} menu={menu}/>}
      {loadState ? <Loader/> :
        <section className="main-content">
          {children}
          <Footer/>
        </section>
      }
    </main>
  );
}

export default MainLayout;