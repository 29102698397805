import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from "@reduxjs/toolkit/query";
import { createLogger } from 'redux-logger';
import { backOfficeQuery } from './backoffice.slice';

const loggerMiddleware = () => {
  if (process.env.NODE_ENV === 'development') return [createLogger({ collapsed: true })];
  return [];
};

export const store = configureStore({
  reducer: {
    [backOfficeQuery.reducerPath]: backOfficeQuery.reducer,
  },
  middleware: initMiddleware => initMiddleware().concat(backOfficeQuery.middleware).concat(loggerMiddleware()),
  devTools: false
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch);