import { Breadcrumb } from "react-bootstrap";

const Breadcrumbs = ({ breadcrumbs }: { breadcrumbs: IBreadcrumbs[] }) => (
  <Breadcrumb>
    {(breadcrumbs && breadcrumbs.length > 1) && breadcrumbs.map((breadcrumb: IBreadcrumbs, index: number) => (
      <Breadcrumb.Item key={index} href={`/${breadcrumb.href}`} active={breadcrumbs.length === index + 1} >{breadcrumb.title}</Breadcrumb.Item>
    ))}
  </Breadcrumb>
);

export default Breadcrumbs;