import { BaseQueryFn, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AppInfo } from '../utils/constant';


const getBaseUrl = () => {
  let CURRENT_HOSTNAME = window.location.hostname, backoffice = AppInfo.PROD_BASEURL;
  if (AppInfo.LOCALHOST === CURRENT_HOSTNAME || AppInfo.STAGING_HOSTNAME === CURRENT_HOSTNAME) backoffice = AppInfo.BETA_BASEURL;
  else backoffice = AppInfo.PROD_BASEURL;

  return {backoffice};
}

export const backOfficeBaseQuery: BaseQueryFn = fetchBaseQuery({
  baseUrl: getBaseUrl()['backoffice'],

  prepareHeaders: (headers: Headers) => {
    headers.set("Content-Type", 'application/json')
    return headers
  }
});

export const backOfficeQuery = createApi({
  baseQuery: backOfficeBaseQuery,
  reducerPath: 'backOfficeQuery',
  tagTypes: [],
  endpoints: (builder) => ({
    getTopic: builder.query<IApiResponse<ITopic>, string | undefined>({
      query: ref => `/docs-service/directory/topics/${ref}`
    }),
    getContent: builder.query<IApiResponse<IContent>, string>({
      query: (ref) => `/docs-service/directory/content/${ref}`
    }),
  }),
});

export const {useGetTopicQuery, useGetContentQuery} = backOfficeQuery;