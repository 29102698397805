import {Link} from "react-router-dom";
import {Col} from "react-bootstrap";
import {TopicNavs} from "../../utils/mapper";
import {Fragment} from "react";

const Topics = () => (
  <Fragment>
    {TopicNavs.map((item: ITopicNav, index: number) =>
      <Col sm={6} lg={4} xxl={3} key={index} className="my-3 my-lg-5">
        <h5>{item.title}</h5>
        <div className="mt-3">
          {item.actions.map((action: ITopicAction, index: number) =>
            <p key={index} className="my-2">
              {action.type === "link" ?
                <a href={action.href} target="_blank" rel="noreferrer">{action.title} &#8599;</a>
                : <Link to={`/${action.href}`}>{action.title}</Link>
              }
            </p>
          )}
        </div>
      </Col>
    )}
  </Fragment>
);

export default Topics;