import {Image} from "react-bootstrap";
import toast from "react-hot-toast";
import ThumbsUpSVG from "../../assets/images/thumbs-up.svg";
import ThumbsDownVG from "../../assets/images/thumbs-down.svg";

const Footer = () => {

  const docsRating = (): string => toast.success(`We have received your feedback.`)

  return (
    <footer>
      <section className="footer">
        <h5 className="mb-5">Help and Support</h5>
        <div className="d-flex flex-wrap gap-4 gap-xl-2 justify-content-between">
          <div>
            <p>Did this document help?</p>
            <div className="d-flex gap-3 mt-2">
              <span onClick={docsRating} className="border border-primary rounded-circle p-2 cursor-pointer">
                <Image src={ThumbsUpSVG} alt="THUMBS-UP" width={22} height={22} className="m-1"/>
              </span>
              <span onClick={docsRating} className="border border-primary rounded-circle p-2 cursor-pointer">
                <Image src={ThumbsDownVG} alt="THUMBS-DOWN" width={22} height={22} className="m-1"/>
              </span>
            </div>
          </div>
          <div style={{maxWidth: "510px"}}>
            <p className="mb-2 fw-bold">We are improving these documentation</p>
            <p>
              We are creating comprehensive guides to help you get started with booking trips, collecting orders, and
              manging your fleet and drivers with truQ. Let's jump right in!
            </p>
          </div>
          <div>
            <p className="mb-2 fw-bold">Still need help?</p>
            <a href="mailto:support@mytruq.com">Contact Support</a>
          </div>
        </div>
      </section>
    </footer>
  );
}

export default Footer;