import {useMemo, useState} from "react";
import {Params, useParams} from "react-router-dom";
import Actions from "../components/modules/Actions";
import MainLayout from "../components/layouts/MainLayout";
import PageLayout from "../components/layouts/PageLayout";
import {useGetTopicQuery} from "../store/backoffice.slice";
import {MockTopic} from "../mocks/MockTopic";

const Topic = () => {
  const {topic_ref}: Readonly<Params> = useParams();
  const [topic, setTopic] = useState<ITopic>(MockTopic);
  const {data: _topic, isLoading} = useGetTopicQuery(topic_ref);

  useMemo(() => {
    const data = (isLoading || !_topic) ? MockTopic : _topic.data;
    setTopic(data);
  }, [_topic]);

  return (
    <MainLayout loadState={isLoading} title={topic.name} menu={topic.sidebarTree}>
      <section>
        <PageLayout title={topic.name} media={topic.media_action} description={topic.intro}
                    breadcrumbs={topic.breadcrumbs}/>
        <Actions actions={topic.actions} menu={topic.sidebarTree}/>
        {/* {topic?.actions && topic.actions.map((item ,))} */}
      </section>
    </MainLayout>
  );
}

export default Topic;