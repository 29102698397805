import { Root, createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import './assets/scss/styles.scss';
import { store } from './store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SideMenuContextProvider } from './components/modules/useToggleSideMenu';


const AppRoot = () => (
  <Provider store={store}>
    <SideMenuContextProvider>
      <App />
    </SideMenuContextProvider>
  </Provider>
);

const root: Root = createRoot(document.getElementById('root') as HTMLElement);
root.render(<AppRoot />);
reportWebVitals();
