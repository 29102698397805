import {Fragment} from "react";
import {Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

const Actions = ({actions, menu}: { actions?: IAction[], menu?: ISideMenu[] }) => {
  const navigate = useNavigate();

  return (
    <>
      {actions && actions.map((item, index) => (
        <Fragment key={index}>
          {item.type === "BUTTON" &&
            <Button className="mt-5" onClick={() => navigate(`/${item.url}`, {state: menu})}>{item.title}</Button>}
        </Fragment>
      ))}
    </>
  )
};
export default Actions;