import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import MenuSVG from "../../assets/images/menu.svg";
import LogoSVG from "../../assets/images/truq-logo.png";
import {useSideNav} from "../modules/useToggleSideMenu";

const Header = () => {
  const menuMode = useSideNav();

  return (
    <header className="fixed-top">
      <nav>
        <Link to={"/"}>
          <Image src={LogoSVG} alt="LOGO" width={69} height={26} />
        </Link>
        <div className="d-flex flex-fill gap-3" style={{ maxWidth: "400px" }}>
          {/*<Form.Control type="search" placeholder="Normal text"/>*/}
          <Image src={MenuSVG} alt="MENU" className="d-block d-lg-none" onClick={() => menuMode.toggleSideNav()}/>
        </div>
      </nav>
    </header>
  );
}

export default Header;