const Loader = () => (
  <div className="page-loader">
    <div className="page-loader-backdrop" >
      <div className="spinner">
        <div className="double-bounce1" />
        <div className="double-bounce2" />
      </div>
    </div>
  </div>
)

export default Loader;